import React, { useCallback, useEffect, useState } from "react";
import styles from "./navbar.module.scss";
import { LuMenuSquare } from "react-icons/lu";
import logo from "../assets/images/logo2.png";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../lang_select";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { RiAccountCircleLine } from "react-icons/ri";

export default function Navbar(props) {
  const [toggle, setToggle] = useState(false);
  const { t, i18n } = useTranslation();
  const [scr, setScr] = useState(false);

  const scrollHand = useCallback(() => {
    window.scrollY !== 0 ? setScr(false) : setScr(true);
  }, []);

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);

    window.addEventListener("scroll", scrollHand);

    return () => {
      window.removeEventListener("scroll", scrollHand);
    };
  }, [i18n, scrollHand]);

  return (
    <div className={styles.navbar}>
      <div className={styles.main}>
        <div className={styles.responsive}>
          <NavLink to="/">
            <a className={styles.alone}>
              <img src={logo} alt="logo" />
              SerenMebel
            </a>
          </NavLink>
          <LuMenuSquare
            className={styles.resp}
            onClick={() => {
              setToggle(!toggle);
            }}
          />
          {/* <NavLink to={"/account"}>
            <div className={styles.signin}>
              <RiAccountCircleLine />
            </div>
          </NavLink> */}
          <div className={styles.changelang}>
            <ChangeLanguage />
          </div>
        </div>
        {/* responsive qismini tugashi */}
        <div className={styles.nvg} style={toggle ? { display: "flex" } : {}}>
          <Link
            to={props.haqida}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <a
              onClick={() => {
                toggle && setToggle(!toggle);
              }}
            >
              {t("haqida")}
            </a>
          </Link>
          <Link
            to={props.mahsulot}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <a
              onClick={() => {
                toggle && setToggle(!toggle);
              }}
            >
              {t("mahsulot")}
            </a>
          </Link>
          <Link
            to={props.xizmat}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <a
              onClick={() => {
                toggle && setToggle(!toggle);
              }}
            >
              {t("xizmat")}
            </a>
          </Link>
          <Link
            to={props.aloqa}
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <a
              onClick={() => {
                toggle && setToggle(!toggle);
              }}
            >
              <button>{t("contact")}</button>
            </a>
          </Link>
          {/* <NavLink to={"/account"}>
            <div className={styles.signin}>
              <RiAccountCircleLine />
            </div>
          </NavLink> */}
        </div>
      </div>
      <div
        className={styles.overlay}
        onClick={() => {
          toggle && setToggle(!toggle);
        }}
        style={toggle ? { height: "800px" } : {}}
      ></div>
    </div>
  );
}
