// oshxona HI TECH rasmlari
import o_h_1 from "../assets/images/seren/oshxona/HI-TECH/3.jpg";
import o_h_2 from "../assets/images/seren/oshxona/HI-TECH/13.jpg";
import o_h_3 from "../assets/images/seren/oshxona/HI-TECH/316.jpg";
import o_h_4 from "../assets/images/seren/oshxona/HI-TECH/image.webp";
import o_h_5 from "../assets/images/seren/oshxona/HI-TECH/image2.webp";
import o_h_6 from "../assets/images/seren/oshxona/HI-TECH/image3.webp";
import o_h_7 from "../assets/images/seren/oshxona/HI-TECH/image4.webp";
import o_h_8 from "../assets/images/seren/oshxona/HI-TECH/image5.webp";
import o_h_9 from "../assets/images/seren/oshxona/HI-TECH/image51.webp";
import o_h_10 from "../assets/images/seren/oshxona/HI-TECH/image6.webp";
import o_h_11 from "../assets/images/seren/oshxona/HI-TECH/image7.webp";
import o_h_12 from "../assets/images/seren/oshxona/HI-TECH/image8.webp";
import o_h_13 from "../assets/images/seren/oshxona/HI-TECH/image9.webp";
import o_h_14 from "../assets/images/seren/oshxona/HI-TECH/image10.webp";
import o_h_15 from "../assets/images/seren/oshxona/HI-TECH/image11.webp";
import o_h_16 from "../assets/images/seren/oshxona/HI-TECH/image12.webp";
import o_h_17 from "../assets/images/seren/oshxona/HI-TECH/image13.webp";
import o_h_18 from "../assets/images/seren/oshxona/HI-TECH/image14.webp";
import o_h_19 from "../assets/images/seren/oshxona/HI-TECH/image15.webp";
import o_h_20 from "../assets/images/seren/oshxona/HI-TECH/image16.webp";
import o_h_21 from "../assets/images/seren/oshxona/HI-TECH/image17.webp";
import o_h_22 from "../assets/images/seren/oshxona/HI-TECH/image18.webp";
import o_h_23 from "../assets/images/seren/oshxona/HI-TECH/image19.webp";
import o_h_24 from "../assets/images/seren/oshxona/HI-TECH/image20.webp";
import o_h_25 from "../assets/images/seren/oshxona/HI-TECH/image21.webp";
import o_h_26 from "../assets/images/seren/oshxona/HI-TECH/image22.webp";
import o_h_27 from "../assets/images/seren/oshxona/HI-TECH/image23.webp";
import o_h_28 from "../assets/images/seren/oshxona/HI-TECH/image24.webp";
import o_h_29 from "../assets/images/seren/oshxona/HI-TECH/image25.webp";
import o_h_30 from "../assets/images/seren/oshxona/HI-TECH/image26.webp";
import o_h_31 from "../assets/images/seren/oshxona/HI-TECH/image27.webp";
import o_h_32 from "../assets/images/seren/oshxona/HI-TECH/image28.webp";
import o_h_33 from "../assets/images/seren/oshxona/HI-TECH/image29.webp";
import o_h_34 from "../assets/images/seren/oshxona/HI-TECH/image30.webp";
import o_h_35 from "../assets/images/seren/oshxona/HI-TECH/image31.webp";
import o_h_36 from "../assets/images/seren/oshxona/HI-TECH/image32.webp";
import o_h_37 from "../assets/images/seren/oshxona/HI-TECH/image33.webp";
import o_h_38 from "../assets/images/seren/oshxona/HI-TECH/image34.jpg";
import o_h_39 from "../assets/images/seren/oshxona/HI-TECH/image35.webp";
import o_h_40 from "../assets/images/seren/oshxona/HI-TECH/image36.jpg";
import o_h_41 from "../assets/images/seren/oshxona/HI-TECH/image37.jpg";
import o_h_42 from "../assets/images/seren/oshxona/HI-TECH/image38.jpg";
import o_h_43 from "../assets/images/seren/oshxona/HI-TECH/image39.jpg";
import o_h_44 from "../assets/images/seren/oshxona/HI-TECH/image40.jpg";
import o_h_45 from "../assets/images/seren/oshxona/HI-TECH/image41.jpg";
import o_h_46 from "../assets/images/seren/oshxona/HI-TECH/image42.jpg";
import o_h_47 from "../assets/images/seren/oshxona/HI-TECH/image43.jpg";
import o_h_48 from "../assets/images/seren/oshxona/HI-TECH/image44.jpg";
import o_h_49 from "../assets/images/seren/oshxona/HI-TECH/image45.jpg";
import o_h_50 from "../assets/images/seren/oshxona/HI-TECH/image46.jpg";
import o_h_51 from "../assets/images/seren/oshxona/HI-TECH/image47.jpg";
import o_h_52 from "../assets/images/seren/oshxona/HI-TECH/image48.jpg";
import o_h_53 from "../assets/images/seren/oshxona/HI-TECH/image49.webp";
import o_h_54 from "../assets/images/seren/oshxona/HI-TECH/image50.jpg";
import o_h_55 from "../assets/images/seren/oshxona/HI-TECH/image51.jpg";
import o_h_56 from "../assets/images/seren/oshxona/HI-TECH/image52.jpg";
import o_h_57 from "../assets/images/seren/oshxona/HI-TECH/image53.png";
import o_h_58 from "../assets/images/seren/oshxona/HI-TECH/image54.jpg";

// oshxona Klassik rasmlari
import o_k_1 from "../assets/images/seren/oshxona/klassik/image1.jpg";
import o_k_2 from "../assets/images/seren/oshxona/klassik/image2.jpg";
import o_k_3 from "../assets/images/seren/oshxona/klassik/image3.jpg";
import o_k_4 from "../assets/images/seren/oshxona/klassik/image4.jpeg";
import o_k_5 from "../assets/images/seren/oshxona/klassik/image5.jpg";
import o_k_6 from "../assets/images/seren/oshxona/klassik/image6.jpeg";
import o_k_7 from "../assets/images/seren/oshxona/klassik/image7.webp";
import o_k_8 from "../assets/images/seren/oshxona/klassik/image8.jpg";
import o_k_9 from "../assets/images/seren/oshxona/klassik/image9.jpg";
import o_k_10 from "../assets/images/seren/oshxona/klassik/image10.jpg";
import o_k_11 from "../assets/images/seren/oshxona/klassik/image11.jpg";
import o_k_12 from "../assets/images/seren/oshxona/klassik/image12.jpg";
import o_k_13 from "../assets/images/seren/oshxona/klassik/image13.webp";
import o_k_14 from "../assets/images/seren/oshxona/klassik/image14.webp";
import o_k_15 from "../assets/images/seren/oshxona/klassik/image15.webp";
import o_k_16 from "../assets/images/seren/oshxona/klassik/image16.webp";
import o_k_17 from "../assets/images/seren/oshxona/klassik/image17.webp";
import o_k_18 from "../assets/images/seren/oshxona/klassik/image18.jpg";
import o_k_19 from "../assets/images/seren/oshxona/klassik/image19.jpg";
import o_k_20 from "../assets/images/seren/oshxona/klassik/image20.jpg";
import o_k_21 from "../assets/images/seren/oshxona/klassik/image21.jpg";
import o_k_22 from "../assets/images/seren/oshxona/klassik/image22.jpg";
import o_k_23 from "../assets/images/seren/oshxona/klassik/image23.jpg";
import o_k_24 from "../assets/images/seren/oshxona/klassik/image24.jpg";
import o_k_25 from "../assets/images/seren/oshxona/klassik/image25.jpg";
import o_k_26 from "../assets/images/seren/oshxona/klassik/image26.webp";
import o_k_27 from "../assets/images/seren/oshxona/klassik/image27.jpg";
import o_k_28 from "../assets/images/seren/oshxona/klassik/image28.jpg";
import o_k_29 from "../assets/images/seren/oshxona/klassik/image29.jpg";
import o_k_30 from "../assets/images/seren/oshxona/klassik/image30.webp";
import o_k_31 from "../assets/images/seren/oshxona/klassik/image31.webp";

// oshxona Royal rasmlari
import o_r_1 from "../assets/images/seren/oshxona/royal/image1.jpg";
import o_r_2 from "../assets/images/seren/oshxona/royal/image2.jpg";
import o_r_3 from "../assets/images/seren/oshxona/royal/image3.jpg";
import o_r_4 from "../assets/images/seren/oshxona/royal/image4.jpg";
import o_r_5 from "../assets/images/seren/oshxona/royal/image5.jpg";
import o_r_6 from "../assets/images/seren/oshxona/royal/image6.jpg";
import o_r_7 from "../assets/images/seren/oshxona/royal/image7.jpg";
import o_r_8 from "../assets/images/seren/oshxona/royal/image8.jpg";
import o_r_9 from "../assets/images/seren/oshxona/royal/image9.jpg";
import o_r_10 from "../assets/images/seren/oshxona/royal/image10.jpg";
import o_r_11 from "../assets/images/seren/oshxona/royal/image11.jpg";
import o_r_12 from "../assets/images/seren/oshxona/royal/image12.jpg";
import o_r_13 from "../assets/images/seren/oshxona/royal/image13.jpg";
import o_r_14 from "../assets/images/seren/oshxona/royal/image14.jpg";
import o_r_15 from "../assets/images/seren/oshxona/royal/image15.jpg";
import o_r_16 from "../assets/images/seren/oshxona/royal/image16.jpg";

// Shkaf categoriyasi rasmlari
import sh_1 from "../assets/images/seren/shkaf/image.webp";
import sh_2 from "../assets/images/seren/shkaf/image1.jpg";
import sh_3 from "../assets/images/seren/shkaf/image2.jpg";
import sh_4 from "../assets/images/seren/shkaf/image3.jpg";
import sh_5 from "../assets/images/seren/shkaf/image4.jpg";
import sh_6 from "../assets/images/seren/shkaf/image5.webp";
import sh_7 from "../assets/images/seren/shkaf/image6.jpg";
import sh_8 from "../assets/images/seren/shkaf/image7.jpg";
import sh_9 from "../assets/images/seren/shkaf/image8.jpg";
import sh_10 from "../assets/images/seren/shkaf/image9.jpg";
import sh_11 from "../assets/images/seren/shkaf/image10.jpg";
import sh_12 from "../assets/images/seren/shkaf/image11.jpg";
import sh_13 from "../assets/images/seren/shkaf/image12.webp";
import sh_14 from "../assets/images/seren/shkaf/image13.jpg";
import sh_15 from "../assets/images/seren/shkaf/image14.jpg";
import sh_16 from "../assets/images/seren/shkaf/image15.jpg";

// ofis categoriysi ramslari
import of_1 from "../assets/images/seren/ofis/image1.webp";
import of_2 from "../assets/images/seren/ofis/image2.jpg";
import of_3 from "../assets/images/seren/ofis/image3.jpg";
import of_4 from "../assets/images/seren/ofis/image4.jpg";
import of_5 from "../assets/images/seren/ofis/image5.jpg";
import of_6 from "../assets/images/seren/ofis/image6.jpg";
import of_7 from "../assets/images/seren/ofis/image7.jpg";
import of_8 from "../assets/images/seren/ofis/image8.jpg";
import of_9 from "../assets/images/seren/ofis/image9.jpg";
import of_10 from "../assets/images/seren/ofis/image10.jpg";
import of_11 from "../assets/images/seren/ofis/image11.jpg";
import of_12 from "../assets/images/seren/ofis/image12.jpg";
import of_13 from "../assets/images/seren/ofis/image13.jpg";
import of_14 from "../assets/images/seren/ofis/image14.jpg";
import of_15 from "../assets/images/seren/ofis/image15.jpg";
import of_16 from "../assets/images/seren/ofis/image16.jpg";
import of_17 from "../assets/images/seren/ofis/image17.jpg";
import of_18 from "../assets/images/seren/ofis/image18.jpg";
import of_19 from "../assets/images/seren/ofis/image19.jpg";
import of_20 from "../assets/images/seren/ofis/image20.jpg";
import of_21 from "../assets/images/seren/ofis/image21.webp";

// yumshoq mebel rasmlari
import y_1 from "../assets/images/seren/yumshoq/image.webp";
import y_2 from "../assets/images/seren/yumshoq/image1.jpg";
import y_3 from "../assets/images/seren/yumshoq/image2.jpg";
import y_4 from "../assets/images/seren/yumshoq/image3.jpg";
import y_5 from "../assets/images/seren/yumshoq/image4.jpg";
import y_6 from "../assets/images/seren/yumshoq/image5.png";
import y_7 from "../assets/images/seren/yumshoq/image6.jpg";
import y_8 from "../assets/images/seren/yumshoq/image7.jpg";
import y_9 from "../assets/images/seren/yumshoq/image8.jpg";
import y_10 from "../assets/images/seren/yumshoq/image9.jpg";
import y_11 from "../assets/images/seren/yumshoq/image10.jpg";
import y_12 from "../assets/images/seren/yumshoq/image11.jpg";
import y_13 from "../assets/images/seren/yumshoq/image12.jpg";
import y_14 from "../assets/images/seren/yumshoq/image13.jpg";
import y_15 from "../assets/images/seren/yumshoq/image14.webp";
import y_16 from "../assets/images/seren/yumshoq/image15.jpg";
import y_17 from "../assets/images/seren/yumshoq/image16.jpg";
import y_18 from "../assets/images/seren/yumshoq/image17.jpg";
import y_19 from "../assets/images/seren/yumshoq/image18.jpg";
import y_20 from "../assets/images/seren/yumshoq/image19.jpg";
import y_21 from "../assets/images/seren/yumshoq/images.jpg";

// Ayvon va zal mebel rasmlari
// ---->------>  Hi Tech
import a_h_1 from "../assets/images/seren/ayvon va zal/hi tech/image1.jpg";
import a_h_2 from "../assets/images/seren/ayvon va zal/hi tech/image2.jpg";
import a_h_3 from "../assets/images/seren/ayvon va zal/hi tech/image3.jpg";
import a_h_4 from "../assets/images/seren/ayvon va zal/hi tech/image4.jpg";
import a_h_5 from "../assets/images/seren/ayvon va zal/hi tech/image5.jpg";
import a_h_6 from "../assets/images/seren/ayvon va zal/hi tech/image6.jpg";
import a_h_7 from "../assets/images/seren/ayvon va zal/hi tech/image7.jpg";
import a_h_8 from "../assets/images/seren/ayvon va zal/hi tech/image8.jpg";
import a_h_9 from "../assets/images/seren/ayvon va zal/hi tech/image9.jpg";
import a_h_10 from "../assets/images/seren/ayvon va zal/hi tech/image10.jpg";
import a_h_11 from "../assets/images/seren/ayvon va zal/hi tech/image11.jpg";
import a_h_12 from "../assets/images/seren/ayvon va zal/hi tech/image12.webp";
import a_h_13 from "../assets/images/seren/ayvon va zal/hi tech/image13.jpg";
import a_h_14 from "../assets/images/seren/ayvon va zal/hi tech/image14.jpg";
import a_h_15 from "../assets/images/seren/ayvon va zal/hi tech/image15.jpg";
import a_h_16 from "../assets/images/seren/ayvon va zal/hi tech/image16.jpg";
import a_h_17 from "../assets/images/seren/ayvon va zal/hi tech/image17.jpg";
import a_h_18 from "../assets/images/seren/ayvon va zal/hi tech/image18.jpg";
import a_h_19 from "../assets/images/seren/ayvon va zal/hi tech/image19.webp";
import a_h_20 from "../assets/images/seren/ayvon va zal/hi tech/image20.jpg";
import a_h_21 from "../assets/images/seren/ayvon va zal/hi tech/image21.jpg";
import a_h_22 from "../assets/images/seren/ayvon va zal/hi tech/image22.jpg";
import a_h_23 from "../assets/images/seren/ayvon va zal/hi tech/image23.jpeg";
import a_h_24 from "../assets/images/seren/ayvon va zal/hi tech/image24.jpg";
// ------>------> Klassik
import a_k_1 from "../assets/images/seren/ayvon va zal/klassik/image1.jpg";
import a_k_2 from "../assets/images/seren/ayvon va zal/klassik/image2.jpg";
import a_k_3 from "../assets/images/seren/ayvon va zal/klassik/image3.jpg";
import a_k_4 from "../assets/images/seren/ayvon va zal/klassik/image4.jpg";
import a_k_5 from "../assets/images/seren/ayvon va zal/klassik/image5.jpg";
import a_k_6 from "../assets/images/seren/ayvon va zal/klassik/image6.jpg";
import a_k_7 from "../assets/images/seren/ayvon va zal/klassik/image7.jpg";
import a_k_8 from "../assets/images/seren/ayvon va zal/klassik/image8.jpg";
import a_k_9 from "../assets/images/seren/ayvon va zal/klassik/image9.jpg";
import a_k_10 from "../assets/images/seren/ayvon va zal/klassik/image10.jpg";
import a_k_11 from "../assets/images/seren/ayvon va zal/klassik/image11.jpg";
import a_k_12 from "../assets/images/seren/ayvon va zal/klassik/image12.webp";
import a_k_13 from "../assets/images/seren/ayvon va zal/klassik/image13.jpg";
import a_k_14 from "../assets/images/seren/ayvon va zal/klassik/image14.jpg";
import a_k_15 from "../assets/images/seren/ayvon va zal/klassik/image15.jpg";
import a_k_16 from "../assets/images/seren/ayvon va zal/klassik/image16.jpg";
import a_k_17 from "../assets/images/seren/ayvon va zal/klassik/image17.jpg";
import a_k_18 from "../assets/images/seren/ayvon va zal/klassik/image18.jpg";
import a_k_19 from "../assets/images/seren/ayvon va zal/klassik/image19.jpg";
import a_k_20 from "../assets/images/seren/ayvon va zal/klassik/image20.jpg";

// yotoqxona mebel rasmlari
import yx_1 from "../assets/images/seren/yotoqxona/image1.jpg";
import yx_2 from "../assets/images/seren/yotoqxona/image2.webp";
import yx_3 from "../assets/images/seren/yotoqxona/image3.jpg";
import yx_4 from "../assets/images/seren/yotoqxona/image4.jpg";
import yx_5 from "../assets/images/seren/yotoqxona/image5.jpg";
import yx_6 from "../assets/images/seren/yotoqxona/image6.jpg";
import yx_7 from "../assets/images/seren/yotoqxona/image7.jpg";
import yx_8 from "../assets/images/seren/yotoqxona/image8.jpg";
import yx_9 from "../assets/images/seren/yotoqxona/image9.jpg";
import yx_10 from "../assets/images/seren/yotoqxona/image10.jpg";
import yx_11 from "../assets/images/seren/yotoqxona/image11.jpg";
import yx_12 from "../assets/images/seren/yotoqxona/image12.jpg";
import yx_13 from "../assets/images/seren/yotoqxona/image13.jpg";
import yx_14 from "../assets/images/seren/yotoqxona/image14.jpg";
import yx_15 from "../assets/images/seren/yotoqxona/image15.jpg";
import yx_16 from "../assets/images/seren/yotoqxona/image16.jpg";
import yx_17 from "../assets/images/seren/yotoqxona/image17.jpg";

// bolalar mebel rasmlari
import b_1 from '../assets/images/seren/bolalar/image.webp';
import b_2 from '../assets/images/seren/bolalar/image1.jpg';
import b_3 from '../assets/images/seren/bolalar/image2.jpg';
import b_4 from '../assets/images/seren/bolalar/image3.jpg';
import b_5 from '../assets/images/seren/bolalar/image4.jpg';
import b_6 from '../assets/images/seren/bolalar/image5.jpg';
import b_7 from '../assets/images/seren/bolalar/image6.jpg';
import b_8 from '../assets/images/seren/bolalar/image7.jpg';
import b_9 from '../assets/images/seren/bolalar/image8.jpg';
import b_10 from '../assets/images/seren/bolalar/image9.jpg';
import b_11 from '../assets/images/seren/bolalar/image10.png';
import b_12 from '../assets/images/seren/bolalar/image11.jpg';
import b_13 from '../assets/images/seren/bolalar/image12.jpg';
import b_14 from '../assets/images/seren/bolalar/image13.jpg';
import b_15 from '../assets/images/seren/bolalar/image14.jpg';
import b_16 from '../assets/images/seren/bolalar/image15.png';
import b_17 from '../assets/images/seren/bolalar/image16.jpg';
import b_18 from '../assets/images/seren/bolalar/image17.jpg';
import b_19 from '../assets/images/seren/bolalar/image18.webp';

// polkalar va stellaj mebel rasmlari
import p_s_1 from '../assets/images/seren/polkalar va stellaj/image.webp';
import p_s_2 from '../assets/images/seren/polkalar va stellaj/image1.jpg';
import p_s_3 from '../assets/images/seren/polkalar va stellaj/image2.jpg';
import p_s_4 from '../assets/images/seren/polkalar va stellaj/image3.jpg';
import p_s_5 from '../assets/images/seren/polkalar va stellaj/image4.jpg';
import p_s_6 from '../assets/images/seren/polkalar va stellaj/image5.jpg';
import p_s_7 from '../assets/images/seren/polkalar va stellaj/image6.jpg';
import p_s_8 from '../assets/images/seren/polkalar va stellaj/image7.jpg';
import p_s_9 from '../assets/images/seren/polkalar va stellaj/image8.jpg';
import p_s_10 from '../assets/images/seren/polkalar va stellaj/image9.webp';
import p_s_11 from '../assets/images/seren/polkalar va stellaj/image10.jpg';
import p_s_12 from '../assets/images/seren/polkalar va stellaj/image11.jpg';
import p_s_13 from '../assets/images/seren/polkalar va stellaj/image12.jpg';
import p_s_14 from '../assets/images/seren/polkalar va stellaj/image13.jpg';
import p_s_15 from '../assets/images/seren/polkalar va stellaj/image14.jpg';
import p_s_16 from '../assets/images/seren/polkalar va stellaj/image15.jpg';
import p_s_17 from '../assets/images/seren/polkalar va stellaj/image16.jpg';
import p_s_18 from '../assets/images/seren/polkalar va stellaj/image17.jpg';
import p_s_19 from '../assets/images/seren/polkalar va stellaj/image18.jpg';
import p_s_20 from '../assets/images/seren/polkalar va stellaj/image19.jpg';
import p_s_21 from '../assets/images/seren/polkalar va stellaj/image20.jpg';
import p_s_22 from '../assets/images/seren/polkalar va stellaj/image21.webp';

// stol stul mebel rasmlari
import s_1 from '../assets/images/s1.jpg';
import s_2 from '../assets/images/s2.jpg';
import s_3 from '../assets/images/s3.jpg';
import s_4 from '../assets/images/s4.jpg';
import s_5 from '../assets/images/m12.jpg';
import s_6 from '../assets/images/m8.jpg';
 
export const info_furniture = [
  {
    id: 1,
    furniture_category: "Oshxona",
    image:o_h_1,
    categories: [
      {
        category_name: "HI Tech",
        furniture_category_name: "Oshxona",
        category_image:o_h_1,
        initial_four:[o_h_1,o_h_2,o_h_3,o_h_4],
        category_furniture_images: [
          { furniture_name: "AA-1123", furniture: o_h_1 },
          { furniture_name: "AA-1124", furniture: o_h_2 },
          { furniture_name: "AA-1125", furniture: o_h_3 },
          { furniture_name: "AA-1126", furniture: o_h_4 },
          { furniture_name: "AA-1127", furniture: o_h_5 },
          { furniture_name: "AA-1128", furniture: o_h_6 },
          { furniture_name: "AA-1129", furniture: o_h_7 },
          { furniture_name: "AA-1130", furniture: o_h_8 },
          { furniture_name: "AA-1131", furniture: o_h_9 },
          { furniture_name: "AA-1132", furniture: o_h_10 },
          { furniture_name: "AA-1133", furniture: o_h_11 },
          { furniture_name: "AA-1134", furniture: o_h_12 },
          { furniture_name: "AA-1135", furniture: o_h_13 },
          { furniture_name: "AA-1136", furniture: o_h_14 },
          { furniture_name: "AA-1137", furniture: o_h_15 },
          { furniture_name: "AA-1138", furniture: o_h_16 },
          { furniture_name: "AA-1139", furniture: o_h_17 },
          { furniture_name: "AA-1140", furniture: o_h_18 },
          { furniture_name: "AA-1141", furniture: o_h_19 },
          { furniture_name: "AA-1142", furniture: o_h_20 },
          { furniture_name: "AA-1143", furniture: o_h_21 },
          { furniture_name: "AA-1144", furniture: o_h_22 },
          { furniture_name: "AA-1145", furniture: o_h_23 },
          { furniture_name: "AA-1146", furniture: o_h_24 },
          { furniture_name: "AA-1147", furniture: o_h_25 },
          { furniture_name: "AA-1148", furniture: o_h_26 },
          { furniture_name: "AA-1149", furniture: o_h_27 },
          { furniture_name: "AA-1150", furniture: o_h_28 },
          { furniture_name: "AA-1151", furniture: o_h_29 },
          { furniture_name: "AA-1152", furniture: o_h_30 },
          { furniture_name: "AA-1153", furniture: o_h_31 },
          { furniture_name: "AA-1154", furniture: o_h_32 },
          { furniture_name: "AA-1155", furniture: o_h_33 },
          { furniture_name: "AA-1156", furniture: o_h_34 },
          { furniture_name: "AA-1157", furniture: o_h_35 },
          { furniture_name: "AA-1158", furniture: o_h_36 },
          { furniture_name: "AA-1159", furniture: o_h_37 },
          { furniture_name: "AA-1160", furniture: o_h_38 },
          { furniture_name: "AA-1161", furniture: o_h_39 },
          { furniture_name: "AA-1162", furniture: o_h_40 },
          { furniture_name: "AA-1163", furniture: o_h_41 },
          { furniture_name: "AA-1164", furniture: o_h_42 },
          { furniture_name: "AA-1165", furniture: o_h_43 },
          { furniture_name: "AA-1166", furniture: o_h_44 },
          { furniture_name: "AA-1167", furniture: o_h_46 },
          { furniture_name: "AA-1168", furniture: o_h_47 },
          { furniture_name: "AA-1169", furniture: o_h_48 },
          { furniture_name: "AA-1170", furniture: o_h_49 },
          { furniture_name: "AA-1171", furniture: o_h_50 },
          { furniture_name: "AA-1172", furniture: o_h_51 },
          { furniture_name: "AA-1173", furniture: o_h_52 },
          { furniture_name: "AA-1174", furniture: o_h_53 },
          { furniture_name: "AA-1175", furniture: o_h_54 },
          { furniture_name: "AA-1176", furniture: o_h_55 },
          { furniture_name: "AA-1177", furniture: o_h_56 },
          { furniture_name: "AA-1178", furniture: o_h_57 },
          { furniture_name: "AA-1179", furniture: o_h_58 },
          
        ],
      },
      {
        category_name: "Klassik",
        furniture_category_name: "Oshxona",
        category_image:o_k_1,
        initial_four:[o_k_1,o_k_2,o_k_3,o_k_4],
        category_furniture_images: [
            {furniture_name:'AA-1123',furniture:o_k_1},
            {furniture_name:'AA-1124',furniture:o_k_2},
            {furniture_name:'AA-1125',furniture:o_k_3},
            {furniture_name:'AA-1126',furniture:o_k_4},
            {furniture_name:'AA-1127',furniture:o_k_5},
            {furniture_name:'AA-1128',furniture:o_k_6},
            {furniture_name:'AA-1129',furniture:o_k_7},
            {furniture_name:'AA-1130',furniture:o_k_8},
            {furniture_name:'AA-1131',furniture:o_k_9},
            {furniture_name:'AA-1132',furniture:o_k_10},
            {furniture_name:'AA-1133',furniture:o_k_11},
            {furniture_name:'AA-1134',furniture:o_k_12},
            {furniture_name:'AA-1135',furniture:o_k_13},
            {furniture_name:'AA-1136',furniture:o_k_14},
            {furniture_name:'AA-1137',furniture:o_k_15},
            {furniture_name:'AA-1138',furniture:o_k_16},
            {furniture_name:'AA-1139',furniture:o_k_17},
            {furniture_name:'AA-1140',furniture:o_k_18},
            {furniture_name:'AA-1141',furniture:o_k_19},
            {furniture_name:'AA-1142',furniture:o_k_20},
            {furniture_name:'AA-1143',furniture:o_k_21},
            {furniture_name:'AA-1144',furniture:o_k_22},
            {furniture_name:'AA-1145',furniture:o_k_23},
            {furniture_name:'AA-1146',furniture:o_k_24},
            {furniture_name:'AA-1147',furniture:o_k_25},
            {furniture_name:'AA-1148',furniture:o_k_26},
            {furniture_name:'AA-1149',furniture:o_k_27},
            {furniture_name:'AA-1150',furniture:o_k_28},
            {furniture_name:'AA-1151',furniture:o_k_29},
            {furniture_name:'AA-1152',furniture:o_k_30},
            {furniture_name:'AA-1153',furniture:o_k_31},
        ],
      },
      {
        category_name: "Royal",
        furniture_category_name: "Oshxona",
        category_image:o_r_1,
        initial_four:[o_r_1,o_r_2,o_r_3,o_r_4],
        category_furniture_images: [
            {furniture_name:'AA-1123',furniture:o_r_1},
            {furniture_name:'AA-1124',furniture:o_r_2},
            {furniture_name:'AA-1125',furniture:o_r_3},
            {furniture_name:'AA-1126',furniture:o_r_4},
            {furniture_name:'AA-1127',furniture:o_r_5},
            {furniture_name:'AA-1128',furniture:o_r_6},
            {furniture_name:'AA-1129',furniture:o_r_7},
            {furniture_name:'AA-1130',furniture:o_r_8},
            {furniture_name:'AA-1131',furniture:o_r_9},
            {furniture_name:'AA-1132',furniture:o_r_10},
            {furniture_name:'AA-1133',furniture:o_r_11},
            {furniture_name:'AA-1134',furniture:o_r_12},
            {furniture_name:'AA-1135',furniture:o_r_13},
            {furniture_name:'AA-1136',furniture:o_r_14},
            {furniture_name:'AA-1137',furniture:o_r_15},
            {furniture_name:'AA-1138',furniture:o_r_16},
        ],
      },
    ],
  },
  {
    id:2,
    furniture_category:"Shkaf",
    image:sh_1,
    initial_four:[sh_1,sh_2,sh_3,sh_4],
    furniture_category_images:[
        {furniture_name:'AA-1123',furniture:sh_1},
        {furniture_name:'AA-1124',furniture:sh_2},
        {furniture_name:'AA-1125',furniture:sh_3},
        {furniture_name:'AA-1126',furniture:sh_4},
        {furniture_name:'AA-1127',furniture:sh_5},
        {furniture_name:'AA-1128',furniture:sh_6},
        {furniture_name:'AA-1129',furniture:sh_7},
        {furniture_name:'AA-1130',furniture:sh_8},
        {furniture_name:'AA-1131',furniture:sh_9},
        {furniture_name:'AA-1132',furniture:sh_10},
        {furniture_name:'AA-1133',furniture:sh_11},
        {furniture_name:'AA-1134',furniture:sh_12},
        {furniture_name:'AA-1135',furniture:sh_13},
        {furniture_name:'AA-1136',furniture:sh_14},
        {furniture_name:'AA-1137',furniture:sh_15},
        {furniture_name:'AA-1138',furniture:sh_16},
    ]
  },
  {
    id:3,
    furniture_category:"Ofis",
    image:of_1,
    initial_four:[of_1,of_2,of_3,of_4],
    furniture_category_images:[
        {furniture_name:'AA-1123',furniture:of_1},
        {furniture_name:'AA-1124',furniture:of_2},
        {furniture_name:'AA-1125',furniture:of_3},
        {furniture_name:'AA-1126',furniture:of_4},
        {furniture_name:'AA-1127',furniture:of_5},
        {furniture_name:'AA-1128',furniture:of_6},
        {furniture_name:'AA-1129',furniture:of_7},
        {furniture_name:'AA-1130',furniture:of_8},
        {furniture_name:'AA-1131',furniture:of_9},
        {furniture_name:'AA-1132',furniture:of_10},
        {furniture_name:'AA-1133',furniture:of_11},
        {furniture_name:'AA-1134',furniture:of_12},
        {furniture_name:'AA-1135',furniture:of_13},
        {furniture_name:'AA-1136',furniture:of_14},
        {furniture_name:'AA-1137',furniture:of_15},
        {furniture_name:'AA-1138',furniture:of_16},
        {furniture_name:'AA-1139',furniture:of_17},
        {furniture_name:'AA-1140',furniture:of_18},
        {furniture_name:'AA-1141',furniture:of_19},
        {furniture_name:'AA-1142',furniture:of_20},
        {furniture_name:'AA-1143',furniture:of_21},
    ]
  },
  {
    id:4,
    furniture_category:"Yumshoq",
    image:y_1,
    initial_four:[y_1,y_2,y_3,y_4],
    furniture_category_images:[
      {furniture_name:"AA-1123",furniture:y_1},
      {furniture_name:"AA-1124",furniture:y_2},
      {furniture_name:"AA-1125",furniture:y_3},
      {furniture_name:"AA-1126",furniture:y_4},
      {furniture_name:"AA-1127",furniture:y_5},
      {furniture_name:"AA-1128",furniture:y_6},
      {furniture_name:"AA-1129",furniture:y_7},
      {furniture_name:"AA-1130",furniture:y_8},
      {furniture_name:"AA-1131",furniture:y_9},
      {furniture_name:"AA-1132",furniture:y_10},
      {furniture_name:"AA-1133",furniture:y_11},
      {furniture_name:"AA-1134",furniture:y_12},
      {furniture_name:"AA-1135",furniture:y_13},
      {furniture_name:"AA-1136",furniture:y_14},
      {furniture_name:"AA-1137",furniture:y_15},
      {furniture_name:"AA-1138",furniture:y_16},
      {furniture_name:"AA-1139",furniture:y_17},
      {furniture_name:"AA-1140",furniture:y_18},
      {furniture_name:"AA-1141",furniture:y_19},
      {furniture_name:"AA-1142",furniture:y_20},
      {furniture_name:"AA-1143",furniture:y_21},
    ]
  },
  {
    id:5,
    furniture_category:"Ayvon va zal",
    image:a_h_1,
    categories:[
      {
        category_name:"Hi tech",
        furniture_category_name:'Ayvon va zal',
        category_image:a_h_1,
        initial_four:[a_h_1,a_h_2,a_h_3,a_h_4],
        category_furniture_images:[
          {furniture_name:"AA-1123",furniture:a_h_1},
          {furniture_name:"AA-1124",furniture:a_h_2},
          {furniture_name:"AA-1125",furniture:a_h_3},
          {furniture_name:"AA-1126",furniture:a_h_4},
          {furniture_name:"AA-1127",furniture:a_h_5},
          {furniture_name:"AA-1128",furniture:a_h_6},
          {furniture_name:"AA-1129",furniture:a_h_7},
          {furniture_name:"AA-1130",furniture:a_h_8},
          {furniture_name:"AA-1131",furniture:a_h_9},
          {furniture_name:"AA-1132",furniture:a_h_10},
          {furniture_name:"AA-1133",furniture:a_h_11},
          {furniture_name:"AA-1134",furniture:a_h_12},
          {furniture_name:"AA-1135",furniture:a_h_13},
          {furniture_name:"AA-1136",furniture:a_h_14},
          {furniture_name:"AA-1137",furniture:a_h_15},
          {furniture_name:"AA-1138",furniture:a_h_16},
          {furniture_name:"AA-1139",furniture:a_h_17},
          {furniture_name:"AA-1140",furniture:a_h_18},
          {furniture_name:"AA-1141",furniture:a_h_19},
          {furniture_name:"AA-1142",furniture:a_h_20},
          {furniture_name:"AA-1143",furniture:a_h_21},
          {furniture_name:"AA-1144",furniture:a_h_22},
          {furniture_name:"AA-1145",furniture:a_h_23},
          {furniture_name:"AA-1146",furniture:a_h_24},
        ]
      },
      {
        category_name:"Klassik",
        furniture_category_name:'Ayvon va zal',
        category_image:a_k_1,
        initial_four:[a_k_1,a_k_2,a_k_3,a_k_4],
        category_furniture_images:[
          {furniture_name:"AA-1123",furniture:a_k_1},
          {furniture_name:"AA-1124",furniture:a_k_2},
          {furniture_name:"AA-1125",furniture:a_k_3},
          {furniture_name:"AA-1126",furniture:a_k_4},
          {furniture_name:"AA-1127",furniture:a_k_5},
          {furniture_name:"AA-1128",furniture:a_k_6},
          {furniture_name:"AA-1129",furniture:a_k_7},
          {furniture_name:"AA-1130",furniture:a_k_8},
          {furniture_name:"AA-1131",furniture:a_k_9},
          {furniture_name:"AA-1132",furniture:a_k_10},
          {furniture_name:"AA-1133",furniture:a_k_11},
          {furniture_name:"AA-1134",furniture:a_k_12},
          {furniture_name:"AA-1135",furniture:a_k_13},
          {furniture_name:"AA-1136",furniture:a_k_14},
          {furniture_name:"AA-1137",furniture:a_k_15},
          {furniture_name:"AA-1138",furniture:a_k_16},
          {furniture_name:"AA-1139",furniture:a_k_17},
          {furniture_name:"AA-1140",furniture:a_k_18},
          {furniture_name:"AA-1141",furniture:a_k_19},
          {furniture_name:"AA-1142",furniture:a_k_20},
        ]
      },
    ],
  },
  {
    id:6,
    furniture_category:"Yotoqxona",
    image:yx_1,
    initial_four:[yx_1,yx_2,yx_3,yx_4],
    furniture_category_images:[
      {furniture_name:'AA-1123',furniture:yx_1},
      {furniture_name:'AA-1124',furniture:yx_2},
      {furniture_name:'AA-1125',furniture:yx_3},
      {furniture_name:'AA-1126',furniture:yx_4},
      {furniture_name:'AA-1127',furniture:yx_5},
      {furniture_name:'AA-1128',furniture:yx_6},
      {furniture_name:'AA-1129',furniture:yx_7},
      {furniture_name:'AA-1130',furniture:yx_8},
      {furniture_name:'AA-1131',furniture:yx_9},
      {furniture_name:'AA-1132',furniture:yx_10},
      {furniture_name:'AA-1133',furniture:yx_11},
      {furniture_name:'AA-1134',furniture:yx_12},
      {furniture_name:'AA-1135',furniture:yx_13},
      {furniture_name:'AA-1136',furniture:yx_14},
      {furniture_name:'AA-1137',furniture:yx_15},
      {furniture_name:'AA-1138',furniture:yx_16},
      {furniture_name:'AA-1139',furniture:yx_17},
    ]
  },
  {
    id:7,
    furniture_category:'Bolalar',
    image:b_1,
    initial_four:[b_1,b_2,b_3,b_4],
    furniture_category_images:[
      {furniture_name:"AA-1123",furniture:b_1},
      {furniture_name:"AA-1124",furniture:b_2},
      {furniture_name:"AA-1125",furniture:b_3},
      {furniture_name:"AA-1126",furniture:b_4},
      {furniture_name:"AA-1127",furniture:b_5},
      {furniture_name:"AA-1128",furniture:b_6},
      {furniture_name:"AA-1129",furniture:b_7},
      {furniture_name:"AA-1130",furniture:b_8},
      {furniture_name:"AA-1131",furniture:b_9},
      {furniture_name:"AA-1132",furniture:b_10},
      {furniture_name:"AA-1133",furniture:b_11},
      {furniture_name:"AA-1134",furniture:b_12},
      {furniture_name:"AA-1135",furniture:b_13},
      {furniture_name:"AA-1136",furniture:b_14},
      {furniture_name:"AA-1137",furniture:b_15},
      {furniture_name:"AA-1138",furniture:b_16},
      {furniture_name:"AA-1139",furniture:b_17},
      {furniture_name:"AA-1140",furniture:b_18},
      {furniture_name:"AA-1141",furniture:b_19},
    ]
  },
  {
    id:8,
    furniture_category:'Polkalar va stellajlar',
    image:p_s_1,
    initial_four:[p_s_1,p_s_2,p_s_3,p_s_4],
    furniture_category_images:[
      {furniture_name:'AA-1123',furniture:p_s_1},
      {furniture_name:'AA-1124',furniture:p_s_2},
      {furniture_name:'AA-1125',furniture:p_s_3},
      {furniture_name:'AA-1126',furniture:p_s_4},
      {furniture_name:'AA-1127',furniture:p_s_5},
      {furniture_name:'AA-1128',furniture:p_s_6},
      {furniture_name:'AA-1129',furniture:p_s_7},
      {furniture_name:'AA-1130',furniture:p_s_8},
      {furniture_name:'AA-1131',furniture:p_s_9},
      {furniture_name:'AA-1132',furniture:p_s_10},
      {furniture_name:'AA-1133',furniture:p_s_11},
      {furniture_name:'AA-1134',furniture:p_s_12},
      {furniture_name:'AA-1135',furniture:p_s_13},
      {furniture_name:'AA-1136',furniture:p_s_14},
      {furniture_name:'AA-1137',furniture:p_s_15},
      {furniture_name:'AA-1138',furniture:p_s_16},
      {furniture_name:'AA-1139',furniture:p_s_17},
      {furniture_name:'AA-1140',furniture:p_s_18},
      {furniture_name:'AA-1141',furniture:p_s_19},
      {furniture_name:'AA-1142',furniture:p_s_20},
      {furniture_name:'AA-1143',furniture:p_s_21},
      {furniture_name:'AA-1144',furniture:p_s_22},
    ]
  },
  {
    id:9,
    furniture_category:'Stol va Stullar',
    image:s_1,
    initial_four:[s_1,s_2,s_3,s_4],
    furniture_category_images:[
      {furniture_name:"AA-1123",furniture:s_1},
      {furniture_name:"AA-1124",furniture:s_2},
      {furniture_name:"AA-1125",furniture:s_3},
      {furniture_name:"AA-1126",furniture:s_4},
      {furniture_name:"AA-1127",furniture:s_5},
      {furniture_name:"AA-1128",furniture:s_6},
    ]
  },
];

export const translate_head = [
  {
    "Oshxona":"Кухня",
    "Shkaf":"Шкаф",
    "Ofis":"Офис",
    "Yumshoq":"Мягкий",
    "Ayvon va zal":"Крыльцо и зал",
    "Yotoqxona":"Спальня",
    "Bolalar":"Дети",
    "Polkalar va stellajlar":"Польки и стойки",
    "Stol va Stullar":"Стол и стулья"
  }
]
