// Mahsulotlar.js
import React, { useContext } from "react";
import styles from "./mahsulot.module.scss";
import { NavLink } from "react-router-dom";
import Scale from "../../FrameMotion/scale";
import { useTranslation } from "react-i18next";
import { info_furniture } from "../../../database/furniture_info";
import { InitialFour, LastContext, OraliqContext } from "../../../App";

export default function Mahsulotlar() {
  const { t } = useTranslation();

  const { setOraliqPage } = useContext(OraliqContext);
  const { setLastPage } = useContext(LastContext);
  const { setInitialFourArray } = useContext(InitialFour);

  return (
    <div className={styles.mahsulot}>
      <p className={styles.mttl}>{t("b_mahsulot")}</p>
      <div className={styles.mbody}>
        {info_furniture.map((r, index) => (
          <Scale>
            <div className={styles.mgrp} key={index}>
              <img src={r.image} alt="stol va stullar" />
              <p>{r.furniture_category}</p>
              {r.categories !== undefined ? (
                <NavLink
                  to={`/oraliq`}
                  onClick={() => {
                    setOraliqPage(r.categories);
                  }}
                >
                  <button>{t("pod")}</button>
                </NavLink>
              ) : (
                <NavLink
                  to={`/ofis/${r.furniture_category}`}
                  onClick={() => {
                    setLastPage(r.furniture_category_images);
                    setInitialFourArray(r.initial_four);
                  }}
                >
                  <button>{t("pod")}</button>
                </NavLink>
              )}
            </div>
          </Scale>
        ))}
      </div>
    </div>
  );
}
