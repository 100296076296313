import React, { useContext, useEffect, useState } from "react";
import "./sarlavha.scss";
import { TranslateLang } from "../../App";
import { translate_head } from "../../database/furniture_info";

export default function Sarlavha({head_text}) {
    
    const {tog_lang} = useContext(TranslateLang)
    const [head_ru,setHead_ru] = useState();

    useEffect(()=>{
        const head_check = translate_head[0];

        switch(head_text){
            case 'Oshxona':
                setHead_ru(head_check['Oshxona']);
                break;
            case 'Shkaf':
                setHead_ru(head_check['Shkaf']);
                break;
            case 'Ofis':
                setHead_ru(head_check['Ofis']);
                break;
            case 'Yumshoq':
                setHead_ru(head_check['Yumshoq']);
                break;
            case 'Ayvon va zal':
                setHead_ru(head_check['Ayvon va zal']);
                break;
            case 'Yotoqxona':
                setHead_ru(head_check['Yotoqxona']);
                break;
            case 'Bolalar':
                setHead_ru(head_check['Bolalar']);
                break;
            case 'Polkalar va stellajlar':
                setHead_ru(head_check['BolPolkalar va stellajlaralar']);
                break;
            case 'Stol va Stullar':
                setHead_ru(head_check['Stol va Stullar']);
                break;
        }
    },[tog_lang])

  return <>
  {tog_lang === 'uz' ? `${head_text} mebellari`:`${head_ru}  мебель`}
  </>;
}
