import React, { useContext, useEffect, useState } from "react";
import styles from "./all_furniture.module.scss";
import { useTranslation } from "react-i18next";
import { SiAntdesign } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";
import { DiMaterializecss } from "react-icons/di";
import { FaFileContract } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { InitialFour, LastContext } from "../App";
import Sarlavha from "../components/header/sarlavha";

export default function Ofis() {
  useEffect(()=>{ window.scrollTo(0, 0);},[])
  const { t } = useTranslation();
  const { furniture_name } = useParams();
  const { lastPage } = useContext(LastContext);
  const {initialFourArray} = useContext(InitialFour);

  return (
    <div>
      {
        <div className={styles.bolalar}>
          <p className={styles.ttl}><Sarlavha head_text={furniture_name} /> </p>
          <div className={styles.cnt}>
            <div className={styles.imgs}>
              {initialFourArray.map((f) => (
                <img
                  src={f}
                  alt="MebelImg"
                />
              ))}
            </div>
            <div className={styles.bolatxt}>
              <p className={styles.txt}>{t("p4")}</p>
            </div>
          </div>
          <Malumot />
          <RasmlarPastki array_furniture={lastPage} />
        </div>
      }
    </div>
  );
}

function RasmlarPastki({ array_furniture }) {
  const { t } = useTranslation();
  return (
    <div className={styles.rasmlar}>
      {array_furniture.map((r, index) => (
        <div className={styles.rasm_grp1} key={index}>
          <img
            src={r.furniture}
            alt="rasm"
          />
          <p>{r.furniture_name}</p>
          {/* <button className={styles.btn_rasm_grp1}>{t("pod")}</button>
          <Link
            to={`/items/${r.id}/${r.description}/${r.image}/${r.price}/${r.name}`}
          >
            <div className={styles.hover_effect}>
              <button>{t("pod")}</button>
            </div>
          </Link> */}
        </div>
      ))}
    </div>
  );
}

function Malumot() {
  const { t } = useTranslation();
  return (
    <div className={styles.malumot}>
      <p className={styles.ittl}>{t("olish_sabab")}</p>
      <div className={styles.fgrp}>
        <div className={styles.grp}>
          <SiAntdesign />
          <p className={styles.txt}>{t("x_txt1")}</p>
        </div>
        <div className={styles.grp}>
          <GrTechnology />
          <p className={styles.txt}>{t("x_txt5")}</p>
        </div>
        <div className={styles.grp}>
          <DiMaterializecss />
          <p className={styles.txt}>{t("x_txt8")}</p>
        </div>
        <div className={styles.grp}>
          <FaFileContract />
          <p className={styles.txt}>{t("x_txt11")}</p>
        </div>
      </div>
    </div>
  );
}
