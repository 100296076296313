import { Routes, Route } from "react-router-dom";
import Navbar from "./layout/navbar";
import Footer from "./layout/footer";
import { routes } from "./layout/routeConfig.js";
import LoadPart from "./components/loadPart";
import React, { useState } from "react";

export const OraliqContext = React.createContext();
export const LastContext = React.createContext();
export const InitialFour = React.createContext();
export const TranslateLang = React.createContext();

function App() {
  const [oraliqPage, setOraliqPage] = useState();
  const [lastPage, setLastPage] = useState([]);
  const [initialFourArray, setInitialFourArray] = useState([]);
  const [tog_lang, setTog_lang] = useState("uz");
  return (
    <>
      <OraliqContext.Provider value={{ oraliqPage, setOraliqPage }}>
        <LastContext.Provider value={{ lastPage, setLastPage }}>
          <InitialFour.Provider
            value={{ initialFourArray, setInitialFourArray }}
          >
            <TranslateLang.Provider value={{tog_lang,setTog_lang}}>
              <LoadPart />
              <Navbar
                mahsulot="mahsulot"
                xizmat="xizmat"
                haqida="haqida"
                aloqa="aloqa"
              />
              <Routes>
                {routes.map(({ id, ...rest }) => (
                  <Route key={id} {...rest} />
                ))}
              </Routes>
              <Footer mahsulot="mahsulot" xizmat="xizmat" haqida="haqida" />
            </TranslateLang.Provider>
          </InitialFour.Provider>
        </LastContext.Provider>
      </OraliqContext.Provider>
    </>
  );
}

export default App;
