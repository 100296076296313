import React, { useContext, useEffect } from "react";
import styles from "./oraliq_pages.module.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InitialFour, LastContext, OraliqContext } from "../App";


export default function Oraliq() {
  useEffect(()=>{ window.scrollTo(0, 0);},[])
  const { t } = useTranslation();

  const { oraliqPage } = useContext(OraliqContext);
  const { setLastPage } = useContext(LastContext);
  const { setInitialFourArray } = useContext(InitialFour);

  return (
    <div>
      {
        <div className={styles.oraliqo}>
          {oraliqPage.map((r, index) => (
            <div className={styles.oraliq_grp} key={index}>
              <img src={r.category_image} alt="hi_tech" />
              <p className={styles.oraliq_ttl}>{r.category_name}</p>
              <NavLink
                to={`/ofis/${r.furniture_category_name}`}
                onClick={() => {
                  setLastPage(r.category_furniture_images);
                  setInitialFourArray(r.initial_four);
                }}
              >
                <button>{t("pod")}</button>
              </NavLink>
            </div>
          ))}
        </div>
      }
    </div>
  );
}
