import React from "react";
import "./info_load.scss";

export default function Info_load() {
  return (
    <div className="head_load">
      <div className="info_load">
        <p>SerenMebel</p>
      </div>
    </div>
  );
}