import React, { useContext} from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.css"; // Main Swiper styles
import "swiper/css/pagination"; // Additional styles for pagination, if needed
import "swiper/css/navigation"; // Additional styles for navigation, if needed

import "./swiper.scss";

import { useTranslation } from "react-i18next";

// import required modules
import {  Navigation } from "swiper/modules";
import { info_furniture } from "../../database/furniture_info";
import { InitialFour, LastContext, OraliqContext } from "../../App";

export default function MahsulotlarSwiper() {
  const { t } = useTranslation();
  const { setOraliqPage } = useContext(OraliqContext);
  const { setLastPage } = useContext(LastContext);
  const { setInitialFourArray } = useContext(InitialFour);
  return (
    <div className="swipergrp">
      <p className="ttl">{t("b_mahsulot")}</p>
      <div className="swgrp">
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          pagination={true}
          navigation={true}
          slidesPerView={1}
          modules={[Navigation]}
          className={`mySwiper`}
        >
          {info_furniture.map((r, index) => (
            <SwiperSlide>
              <div className="mgrp" key={index}>
                <img src={r.image} alt="oshxona mebel" />
                <p>{r.furniture_category}</p>
                {r.categories ? (
                  <NavLink
                    to={`/oraliq`}
                    onClick={() => {
                      setOraliqPage(r.categories);
                    }}
                  >
                    <button>{t("pod")}</button>
                  </NavLink>
                ) : (
                  <NavLink
                    to={`/ofis/${r.furniture_category}`}
                    onClick={() => {
                      setLastPage(r.furniture_category_images);
                      setInitialFourArray(r.initial_four);
                    }}
                  >
                    <button>{t("pod")}</button>
                  </NavLink>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
